export function generateTimestamp() {
	let dateObject = new Date
	let timestamp  = dateObject.getFullYear()
		+ "-"
		+ (dateObject.getMonth() < 9 ? "0" : "")
		+ (dateObject.getMonth() + 1)
		+ "-"
		+ (dateObject.getDate() < 9 ? "0" : "")
		+ dateObject.getDate()
		+ " "
		+ (dateObject.getHours() < 10 ? "0" : "")
		+ dateObject.getHours()
		+ ":"
		+ (dateObject.getMinutes() < 10 ? "0" : "")
		+ dateObject.getMinutes()
		+ ":"
		+ (dateObject.getSeconds() < 10 ? "0" : "")
		+ dateObject.getSeconds()

	return timestamp
}