<template>
	<div class="screen--history">
		<div class="screen--history--upper">
			<h1>
				Storico
			</h1>
		</div>
		<div class="screen--history--table-container">
			<table>
				<tr>
					<th>Data</th>
					<th>Matricola</th>
				</tr>
				<tr v-for="entry in entries" :key="entry.createdAt">
					<td width="1%">{{ formatTimestamp(entry.createdAt) }}</td>
					<td>{{ entry.cardCode }}</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
import { get } from "idb-keyval"

export default {
	name: "HistoryScreen",
	data () {
		return {
			entries: [],
		}
	},
	mounted () {
		get("entries").then(entries => this.entries = entries === undefined ? [] : entries)
	},
	methods: {
		formatTimestamp (timestamp) {
			if (timestamp == "") {
				return ""
			}

			let timestampParts = timestamp.split(" ")
			let dateParts = timestampParts[0].split("-")
			let timeParts = timestampParts[1].split(":")

			return dateParts.reverse().join("/") + " " + timeParts[0] + ":" + timeParts[1]
		}
	}
}
</script>

<style>
.screen--history {
	display: grid;
	grid-template-rows: auto 1fr;
}
.screen--history--upper {
	padding: var(--padding);
}
.screen--history--table-container {
	background-color: #CCCCCC;
}
.screen--history--table-container table {
	width: 100%;
	border-collapse: collapse;
}
.screen--history--table-container table th,
.screen--history--table-container table td {
	white-space: nowrap;
	padding: calc(var(--padding) / 2) var(--padding);
	border: solid 1px #444444;
}
</style>