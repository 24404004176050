<template>
	<div class="screen--sync">
		<h1>
			Invia dati
		</h1>
		<p>
			Sono presenti {{ entries.length }} registrazioni.
		</p>
		<button type="button" @click="syncEntries()">
			Invia dati
		</button>
	</div>
</template>

<script>
import { get, update } from "idb-keyval"

export default {
	name: "SyncScreen",
	data () {
		return {
			entries: [],
		}
	},
	mounted () {
		get("entries").then(entries => this.entries = entries === undefined ? [] : entries)
	},
	methods: {
		syncEntries () {
			let fetchParams = {
				method: "POST",
				mode: "no-cors",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(this.entries)
			}

			fetch("https://www.atc-brescia.it/registrazione.php", fetchParams)
				.then(response => response.text())
				.then(response => {
					console.log(response)

					this.entries = []
					update("entries", entries => [])
				})
				.catch(error => alert(error))
		}
	}
}
</script>

<style>
.screen--sync {
	display: grid;
	grid-template-rows: auto 1fr auto;
	padding: var(--padding);
	gap: var(--padding);
}
.screen--sync > button {
	padding: var(--padding);
}
</style>