<template>
	<div class="screen--home">
		<button type="button" @click="askForLocation()" v-if="location != ''">
			{{ location }}
		</button>
		<button type="button" @click="askForLocation()" v-else>
			Inserisci comune
		</button>
		<div class="screen--home--buttons">
			<router-link to="/sync" tag="button" :disabled="location == ''">
				Invia dati
			</router-link>
			<router-link to="/history" tag="button" :disabled="location == ''">
				Storico
			</router-link>
			<router-link to="/insert" tag="button" :disabled="location == ''">
				Inserisci
			</router-link>
		</div>
	</div>
</template>

<script>
import { get, set } from 'idb-keyval'

export default {
	name: "HomeScreen",
	data () {
		return {
			location: ""
		}
	},
	mounted () {
		get("location").then(location => this.location = location || "")
	},
	methods: {
		askForLocation () {
			this.location = prompt("Inserisci il comune", this.location) || ""
			set("location", this.location)
		}
	}
}
</script>

<style>
.screen--home {
	display: grid;
	grid-template-rows: auto 1fr;
	padding: var(--padding);
	gap: var(--padding);
}
.screen--home > button {
	padding: var(--padding);
}
.screen--home--buttons {
	display: grid;
	gap: var(--padding);
}
@media (orientation: landscape) {
	.screen--home--buttons { grid-template-columns: repeat(3, 1fr); }
}
@media (orientation: portrait) {
	.screen--home--buttons { grid-template-rows: repeat(3, 1fr); }
}
</style>