<template>
	<div class="screen--insert">
		<div class="screen--insert--upper">
			<div class="screen--insert--upper--title">
				<h1>
					Inserisci
				</h1>

				<button type="button" @click="toggleQrCodePreview()">
					<img src="assets/images/icons/ui/qrcode-off.png" alt="QR Code" v-if="showQrCodePreview">
					<img src="assets/images/icons/ui/qrcode.png" alt="QR Code" v-else>
				</button>
			</div>
			<div class="screen--insert--upper--qr-code-preview" v-if="showQrCodePreview">
				<vue-qr-reader
					:stopOnScanned="true"
					:responsive="true"
					@code-scanned="qrCodeScannerCallback"
					@error-captured="qrCodeScannerErrorCallback">
				</vue-qr-reader>
			</div>
			<div class="screen--insert--upper--input-container" v-else>
				<p v-if="cardCode == ''" style="color: #999999">00-00000</p>
				<p v-else>{{ cardCode }}</p>
			</div>
			<table class="screen--insert--upper--geolocation-table">
				<tr>
					<td>Latitudine</td>
					<td><span id="latitude-text">{{ latitude }}</span></td>
				</tr>
				<tr>
					<td>Longitudine</td>
					<td><span id="longitude-text">{{ longitude }}</span></td>
				</tr>
				<tr>
					<td>Precisione (m)</td>
					<td><span id="accuracy-text">{{ formatDecimal(accuracy) }}</span></td>
				</tr>
			</table>
		</div>
		<div class="screen--insert--keypad">
			<button type="button" class="spans-2" @click="addCharacter('1')"><strong>1</strong></button>
			<button type="button" class="spans-2" @click="addCharacter('2')"><strong>2</strong></button>
			<button type="button" class="spans-2" @click="addCharacter('3')"><strong>3</strong></button>
			<button type="button" class="spans-2" @click="addCharacter('4')"><strong>4</strong></button>
			<button type="button" class="spans-2" @click="addCharacter('5')"><strong>5</strong></button>
			<button type="button" class="spans-2" @click="addCharacter('6')"><strong>6</strong></button>
			<button type="button" class="spans-2" @click="addCharacter('7')"><strong>7</strong></button>
			<button type="button" class="spans-2" @click="addCharacter('8')"><strong>8</strong></button>
			<button type="button" class="spans-2" @click="addCharacter('9')"><strong>9</strong></button>
			<button type="button" class="spans-2" @click="addCharacter('0')"><strong>0</strong></button>

			<button type="button" @click="addCharacter('-')"><strong>-</strong></button>

			<button type="button"  class="bg-black" @click="removeCharacter()" :class="{'bg-red': cardCode.length > 0}" :disabled="cardCode.length == 0">
				<strong>DEL</strong>
			</button>

			<button type="button" class="bg-black spans-2" @click="insertRecord()" :class="{'bg-green': cardCode.length > 0}" :disabled="cardCode.length == 0">
				<strong>OK</strong>
			</button>
		</div>
	</div>
</template>

<script>
import { get, update } from "idb-keyval"
import { generateTimestamp } from "@/utils"
import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd'

export default {
	name: "InsertScreen",
	components: {
		VueQrReader,
	},
	data () {
		return {
			positionWatchId: null,
			cardCode: "",
			latitude: "",
			longitude: "",
			accuracy: "",
			showQrCodePreview: false,
		}
	},
	mounted () {
		get("location").then(location => this.location = location || "")

		if ("geolocation" in navigator) {
			this.positionWatchId = navigator.geolocation.watchPosition(this.watchPositionCallback, this.watchPositionErrorCallback, {
				enableHighAccuracy: true,
				maximumAge: 15000,
			})
		}
	},
	destroyed () {
		if (this.positionWatchId !== null) {
			navigator.geolocation.clearWatch(this.positionWatchId);
		}
	},
	methods: {
		addCharacter (char) {
			this.cardCode = this.cardCode + char
		},
		removeCharacter () {
			this.cardCode = this.cardCode.substr(0, this.cardCode.length - 1)
		},
		insertRecord () {
			update("entries", entries => [...(entries || []), {
				location: this.location,
				cardCode: this.cardCode,
				latitude: this.latitude,
				longitude: this.longitude,
				createdAt: generateTimestamp()
			}]).then(() => this.cardCode = "")
		},
		watchPositionCallback (position) {
			// Do not store less accurate measurements
			if (position.coords.accuracy > this.accuracy
				&& this.latitude != ""
				&& this.longitude != ""
				&& this.accuracy != "") {
				return;
			}

			this.latitude  = position.coords.latitude
			this.longitude = position.coords.longitude
			this.accuracy  = position.coords.accuracy

			document.getElementById("latitude-text").classList.add("updated")
			document.getElementById("longitude-text").classList.add("updated")
			document.getElementById("accuracy-text").classList.add("updated")

			setTimeout(() => {
				document.getElementById("latitude-text").classList.remove("updated")
				document.getElementById("longitude-text").classList.remove("updated")
				document.getElementById("accuracy-text").classList.remove("updated")
			}, 2000);
		},
		watchPositionErrorCallback (error) {
			alert("Impossibile determinare la posizione (Codice di errore: " + error.code + " - " + error.message + ").")
		},
		formatDecimal (number, precision = 2) {
			if (number == "") {
				return (0).toFixed(precision)
			}

			return parseFloat(number).toFixed(precision)
		},
		toggleQrCodePreview () {
			this.showQrCodePreview = !this.showQrCodePreview
		},
		qrCodeScannerCallback (code) {
			let possibleRegexes = [
				/^(\d+-\d+)$/,
				/^https?:\/\/(?:www\.)?atc-brescia.it\/qr\.php\?nr=(\d+-\d+).*?$/,
			]

			for (let i = 0; i < possibleRegexes.length; i++) {
				let matches = code.match(possibleRegexes[i]) || []

				if (matches.length == 2) {
					this.cardCode = matches[1]
				}
			}

			this.showQrCodePreview = false
		},
		qrCodeScannerErrorCallback (error) {
			switch (error.name) {
				case "NotAllowedError":
					alert("E' necessario consentire l'uso della fotocamera per poter scansionare un codice QR.")
					break;
				case "NotFoundError":
					alert("Nessuna fotocamera rilevata.")
					break;
				case "NotSupportedError":
					alert("La pagina corrente è servita tramite un contesto non sicuro (HTTP); per interagire con la fotocamera è necessario che sia servita tramite un contesto sicuro (HTTPS).")
					break;
				case "NotReadableError":
					alert("Non è stato possibile accedere alla fotocamera; potrebbe essere già in uso da un'altra applicazione.")
					break;
				case "OverconstrainedError":
					alert("Nessuna tra le fotocamere rilevate soddisfa i requisiti richiesti.")
					break;
				default:
					alert("Errore fotocamera sconosciuto: " + error.message)
			}
		},
	}
}
</script>

<style>
.screen--insert {
	display: grid;
	grid-template-rows: 1fr auto;
}
.screen--insert--upper {
	display: grid;
	grid-template-rows: auto 1fr auto;
	padding: var(--padding);
	gap: var(--padding);
}
.screen--insert--upper--title {
	display: grid;
	grid-template-columns: 1fr auto;
	gap: var(--padding);
}
.screen--insert--upper--title > button > img {
	width: 32px;
	height: 32px;
}
.screen--insert--upper--qr-code-preview {
	display: flex;
	justify-content: center;
	align-items: center;
	border: solid 1px #CCCCCC;
}
.screen--insert--upper--input-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.screen--insert--upper--input-container p {
	font-size: 2rem;
	text-align: center;
}
table.screen--insert--upper--geolocation-table {
	width: 100%;
	border-collapse: collapse;
}
table.screen--insert--upper--geolocation-table tr:first-child td {
	border-top: solid 1px #CCCCCC;
}
table.screen--insert--upper--geolocation-table td {
	border-bottom: solid 1px #CCCCCC;
	padding: calc(var(--padding, 8px) / 2);
}
.screen--insert--keypad {
	display: grid;
	grid-template-rows: repeat(4, auto);
	grid-template-columns: repeat(6, 1fr);
}
.screen--insert--keypad > button {
	padding: calc(var(--padding, 8px) * 2) var(--padding);
	border: 0px;
	border-radius: 0px;
	color: #FFFFFF;
	cursor: pointer;
}
.screen--insert--keypad > button.spans-2 {
	grid-column: span 2;
}
.screen--insert--keypad > button:nth-child(odd) {
	background-color: #666666;
}
.screen--insert--keypad > button:nth-child(even) {
	background-color: #444444;
}
.screen--insert--keypad > button.bg-black {
	background-color: #000000;
}
.screen--insert--keypad > button.bg-red {
	background-color: #C0392B;
}
.screen--insert--keypad > button.bg-green {
	background-color: #27AE60;
}
#latitude-text,
#longitude-text,
#accuracy-text {
	transition: color .7s ease-in-out;
}
#latitude-text.updated,
#longitude-text.updated,
#accuracy-text.updated {
	color: #27AE60;
}
</style>