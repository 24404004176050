import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeScreen from '../views/HomeScreen.vue'
import SyncScreen from '../views/SyncScreen.vue'
import HistoryScreen from '../views/HistoryScreen.vue'
import InsertScreen from '../views/InsertScreen.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'HomeScreen',
		component: HomeScreen
	},
	{
		path: '/sync',
		name: 'SyncScreen',
		component: SyncScreen
	},
	{
		path: '/history',
		name: 'HistoryScreen',
		component: HistoryScreen
	},
	{
		path: '/insert',
		name: 'InsertScreen',
		component: InsertScreen
	},
]

const router = new VueRouter({
	routes
})

export default router
